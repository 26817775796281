import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import SEO from "../../../components/SEO";
import TabArrow from "../components/TabArrow";
import Layout from "../../../components/Layout";
import ModalDriverFreightRegister from "../components/ModalDriverFreightRegister";

import FreightInPreparation from "./preparacao";
import FreightInExecution from "./execucao";
import FreightCompleted from "./entrega";
import FreightPaid from "./pagamento";

import { FiPlus } from "react-icons/fi";
import { ClipLoader } from "react-spinners";

import "./style.css";

const DriversFreights = () => {
    const [openTab, setOpenTab] = useState("Preparação");
    const [openModal, setOpenModal] = useState(false);
    const [loadCount, setLoadCount] = useState(false);
    const [countFreightTab, setCountFreightTab] = useState({});
    const [update, setUpdate] = useState(false);
    const [load, setLoad] = useState(true);
    const [action, setAction] = useState(1);

    useEffect(() => {
        loadList();
    }, [loadCount]);

    const loadList = () => {
        setLoad(true);

        api.get("/adm/driver-freight/list/count", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            setCountFreightTab(response.data);
        });
        setLoad(false);
    };

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const preparationDefault = {
        data: [
            {
                name: "Em rascunho",
                qtd: 2,
            },
            {
                name: "Recusado pelo motorista",
                qtd: 4,
            },
            {
                name: "Aceito pelo motorista",
                qtd: 1,
            },
            {
                name: "Cancelada",
                qtd: 1,
            },
        ],
    };

    const executionDefault = {
        data: [
            {
                name: "Indo carregar",
                qtd: 2,
            },
            {
                name: "Carregando",
                qtd: 4,
            },
            {
                name: "Carga carregada",
                qtd: 1,
            },
            {
                name: "Pronto para iniciar",
                qtd: 1,
            },
            {
                name: "Em rota",
                qtd: 1,
            },
            {
                name: "Parada",
                qtd: 1,
            },
        ],
    };

    const completedDefault = {
        number: 8,
        data: [
            {
                name: "Em rascunho",
                qtd: 2,
            },
            {
                name: "Recusado pelo motorista",
                qtd: 4,
            },
            {
                name: "Aceito pelo motorista",
                qtd: 1,
            },
            {
                name: "Cancelada",
                qtd: 1,
            },
        ],
    };

    const paidDefault = {
        number: 8,
        data: [
            {
                name: "Em rascunho",
                qtd: 2,
            },
            {
                name: "Recusado pelo motorista",
                qtd: 4,
            },
            {
                name: "Aceito pelo motorista",
                qtd: 1,
            },
            {
                name: "Cancelada",
                qtd: 1,
            },
        ],
    };

    return (
        <Layout
            style={{
                minHeight: "unset",
                height: "calc( 100vh - 68px )",
                overflow: "hidden",
            }}>
            <ModalDriverFreightRegister
                actionModal={closeModal}
                openModal={openModal}
                handleSuccess={() => {
                    closeModal();
                    setLoadCount(true);
                    loadList();
                    setAction(action + 1);
                }}
            />
            <SEO title="Viagens" />
            <div className="kanbans m-4">
                <header className="flex justify-between">
                    <div className="flex items-center">
                        <div className="flex">
                            <TabArrow
                                text="Preparação"
                                number={countFreightTab?.preparation}
                                stats={preparationDefault}
                                openTab={openTab}
                                setOpenTab={setOpenTab}
                            />
                            <TabArrow
                                text="Execução"
                                number={countFreightTab?.execution}
                                stats={executionDefault}
                                openTab={openTab}
                                setOpenTab={setOpenTab}
                            />
                            <TabArrow
                                text="Entrega"
                                number={countFreightTab?.completed}
                                stats={completedDefault}
                                openTab={openTab}
                                setOpenTab={setOpenTab}
                            />
                            <TabArrow
                                text="Faturamento"
                                number={countFreightTab?.paid}
                                stats={paidDefault}
                                openTab={openTab}
                                setOpenTab={setOpenTab}
                            />
                        </div>
                    </div>
                    <button
                        onClick={actionModal}
                        className="flex items-center p-2 m-1 ml-2 bg-roxo_oficial rounded-md text-white outline-none">
                        <i>
                            <FiPlus className="w-8 h-8" />{" "}
                        </i>
                        <span>Nova viagem</span>
                    </button>
                </header>
                {load ? (
                    <div className="flex flex-col items-center">
                        <ClipLoader size={20} loading={load} />
                    </div>
                ) : (
                    openTab === "Preparação" && (
                        <FreightInPreparation loadList={update} action={action} setLoadList={setUpdate} />
                    )
                )}
                {openTab === "Execução" && <FreightInExecution />}
                {openTab === "Entrega" && <FreightCompleted />}
                {openTab === "Faturamento" && <FreightPaid />}
            </div>
        </Layout>
    );
};

export default DriversFreights;
