import React, { useRef } from "react";
import TrelloCard from "../../components/TrelloCard";

const FreightPaid = () => {
    const lanes = useRef({
        PAYMENT_ALLOWED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=PAYMENT_ALLOWED&page_size=9999999999",
            title: "Pagamento liberado",
        },
        PAID: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=PAID&page_size=9999999999",
            title: "Pagamento aprovado",
        },
        PAID_CANCELLED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=PAID_CANCELLED&page_size=9999999999",
            title: "Pagamento cancelado",
        },
    });

    return <TrelloCard lanes={lanes} />;
};

export default FreightPaid;
