import React, { useRef } from "react";
import TrelloCard from "../../components/TrelloCard";

const FreightInExecution = () => {
    const lanes = useRef({
        GOING_TO_COLLECT: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=GOING_TO_COLLECT&page_size=9999999999",
            title: "Indo carregar",
        },
        READ_TO_COLLECT: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=READ_TO_COLLECT&page_size=9999999999",
            title: "Carregando",
        },
        COLLECTING: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=COLLECTING&page_size=9999999999",
            title: "Carga carregada",
        },
        READ_TO_TRAVEL: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=READ_TO_TRAVEL&page_size=9999999999",
            title: "Pronto para iniciar",
        },
        TRAVELLING: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=TRAVELLING&page_size=9999999999",
            title: "Em rota",
        },
        HALTED: {
            route: "/adm/driver-freight/list?modalities[]=APP_MOTORISTA&status=HALTED&page_size=9999999999",
            title: "Parada",
        },
    });

    return <TrelloCard lanes={lanes} />;
};

export default FreightInExecution;
